import {
  Button,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHttp } from "../hooks/useHttp";
import { CardIndicator } from "../components/cardIndicator";
import { LineChart } from "../components/lineChart";
import { StackedBarChart } from "../components/stackedBarChart";
import { BarChart } from "../components/barChart";
import { TableChart } from "../components/tableChart";
import { Info } from "@mui/icons-material";
import { BinaryTableChart } from "../components/binaryTableChart";

interface IIndicator {}

const Indicator: React.FC<IIndicator> = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { request, loading } = useHttp();
  const [indicator, setIndicator] = React.useState<any>(undefined);

  const getIndicator = React.useCallback(async () => {
    try {
      await request(
        {
          method: "GET",
          url: `/locations/${params?.location}/indicators/${params?.indicator}/`,
        },
        {
          onCallback: (response: any) => {
            setIndicator(response?.data);
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  }, [params?.indicator, params?.location, request]);

  React.useEffect(() => {
    if (params?.location && params?.indicator) {
      getIndicator();
    }
  }, [getIndicator, params, params?.indicator, params?.location]);

  console.log({
    indicator
  });

  return (
    <Container maxWidth="lg">
      {loading && (
        <Stack mt={6}>
          <LinearProgress />
        </Stack>
      )}
      {!loading && (
        <Stack mt={6} mb={6} gap={3}>
          <Stack
            justifyContent="center"
            alignItems='flex-start'
          >
            <Button
              variant="text"
              onClick={() => navigate(-1)}
              sx={{
                textDecoration: "none",
                '&:hover': {
                  textDecoration: "underline",
                },
                color: 'rgb(0, 78, 112)',
                fontWeight: 700,
                fontFamily: "IBM Plex Sans",
                textTransform: "none",
              }}
            >
              Voltar ao painel de indicadores
            </Button>
          </Stack>
          <Stack 
            flexDirection={{
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }} 
            justifyContent="flex-start" 
            alignItems="flex-start" 
            gap={4}
          >
            <CardIndicator
              locationId={params?.location}
              shortName={indicator?.short_name}
              source={indicator?.source}
              indicatorId={indicator?.id}
              icons={indicator?.icons}
              period={indicator?.period}
            />
            <ListItem disablePadding>
              <ListItemText
                primary="Indicador"
                primaryTypographyProps={{
                  sx: {
                    textAlign: "left",
                    font: "bold 17px / 22px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  },
                }}
                secondary={indicator?.complete_name}
                secondaryTypographyProps={{
                  sx: {
                    textAlign: "left",
                    font: {
                      xs: "bold 30px / 42px IBM Plex Sans",
                      sm: "bold 40px / 52px IBM Plex Sans",
                      md: "bold 40px / 52px IBM Plex Sans",
                      lg: "bold 40px / 52px IBM Plex Sans",
                      xl: "bold 40px / 52px IBM Plex Sans",
                    },
                    letterSpacing: "0px",
                    color: "rgb(7, 94, 137)",
                  },
                }}
              />
            </ListItem>
          </Stack>
          <Divider />

          <Stack gap={4}>
            <Stack
              gap={3}
            >
              <Stack gap={0}>
                <Stack
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={1}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      font: "bold 20px / 25px IBM Plex Sans",
                      letterSpacing: "0px",
                      color: "rgb(7, 94, 137)",
                      wordBreak: "break-all",
                    }}
                  >
                    {indicator?.chart_title}
                  </Typography>
                  <Tooltip 
                    sx={{
                      [`& .${tooltipClasses.tooltip}`]: {
                        maxWidth: 500,
                      },
                    }} 
                    placement="right"
                    title={
                      <Stack>
                        <Typography>
                          Descrição: {indicator?.description}
                        </Typography>
                        <Typography>
                          Fórmula de Cálculo: {indicator?.calculation_formula}
                        </Typography>
                      </Stack>
                    }
                  >
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "700 14px / 18px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  {indicator?.chart_subtitle}
                </Typography>
              </Stack>
              <Typography
                component={'div'}
                sx={{
                  textAlign: "left",
                  font: "18px / 28px IBM Plex Sans",
                  letterSpacing: "0.5px",
                  color: "rgb(109, 110, 113)",
                }}
                dangerouslySetInnerHTML={{ __html: indicator?.content }}
              />
            </Stack>
            {indicator?.chart && indicator?.chart_type === "line" && (
              <LineChart 
                data={indicator?.chart?.data}
                options={indicator?.chart?.options}
              />
            )}
            {indicator?.chart && indicator?.chart_type === "bar" && (
              <BarChart
                data={indicator?.chart?.data}
                options={indicator?.chart?.options}
              />
            )}
            {indicator?.chart && indicator?.chart_type === "stacked_bar" && (
              <StackedBarChart
                data={indicator?.chart?.data}
                options={indicator?.chart?.options}
              />
            )}
            {indicator?.chart && indicator?.chart_type === "table" && (
              <TableChart
                data={indicator?.chart?.data}
                label={indicator?.chart?.label}
                options={indicator?.chart?.options}
              />
            )}

            {indicator?.chart && indicator?.chart_type === "binary_table" && (
              <BinaryTableChart
                data={indicator?.chart?.data}
                label={indicator?.chart?.label}
                options={indicator?.chart?.options}
              />
            )}
          </Stack>

          {indicator?.secundary_chart && (
            <Stack gap={4}>
              <Stack
                gap={2}
              >
                <Stack gap={1}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      font: "bold 20px / 25px IBM Plex Sans",
                      letterSpacing: "0px",
                      color: "rgb(7, 94, 137)",
                      wordBreak: "break-all",
                    }}
                  >
                    {indicator?.secundary_chart_title}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "left",
                      font: "700 14px / 18px IBM Plex Sans",
                      letterSpacing: "0px",
                      color: "rgb(109, 110, 113)",
                    }}
                  >
                    {indicator?.secundary_chart_subtitle}
                  </Typography>
                </Stack>
              </Stack>
              {indicator?.secundary_chart_type === "line" && (
                <LineChart 
                  data={indicator?.secundary_chart?.data}
                  options={indicator?.secundary_chart?.options}
                />
              )}
              {indicator?.secundary_chart_type === "bar" && (
                <BarChart
                  data={indicator?.secundary_chart?.data}
                  options={indicator?.secundary_chart?.options}
                />
              )}
              {indicator?.secundary_chart_type === "stacked_bar" && (
                <StackedBarChart
                  data={indicator?.secundary_chart?.data}
                  options={indicator?.secundary_chart?.options}
                />
              )}
              {indicator?.secundary_chart_type === "table" && (
                <TableChart
                  data={indicator?.secundary_chart?.data}
                  label={indicator?.secundary_chart?.label}
                  options={indicator?.secundary_chart?.options}
                />
              )}

              {indicator?.secundary_chart_type === "binary_table" && (
                <BinaryTableChart
                  data={indicator?.secundary_chart?.data}
                  label={indicator?.secundary_chart?.label}
                  options={indicator?.secundary_chart?.options}
                />
              )}
            </Stack>
          )}
          
        </Stack>
      )}
    </Container>
  );
};

export { Indicator };
