import React, { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_BR";

interface IBarChart {
  data: any;
  options: any;
}

const BarChart: React.FC<IBarChart> = ({
  data,
  options
}) => {
  useEffect(() => {
    const root = am5.Root.new("chartdivBar");

    root.locale = am5locales_pt_BR;

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout,

    }));

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      paddingRight: 20,
      renderer: am5xy.AxisRendererY.new(root, {
       
      })
    }));

    const xRenderer = am5xy.AxisRendererX.new(root, { 
      cellEndLocation: 0.9,
      cellStartLocation: 0.1,
      minGridDistance: 0,      
    });

    xRenderer.grid.template.setAll({
      location: 0
    })


    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        paddingTop: 20,
        paddingBottom: 20,
        categoryField: "year",
        renderer: xRenderer,
      })
    );

    data?.datasets?.forEach((dataset: any, index: any) => {

      let hh: any[] = []

      data?.labels?.forEach((label: any, index: any) => {
        hh.push({
          year: label,
          value: dataset?.data[index]
        })
      })

      xAxis.data.setAll(hh);        

      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: dataset?.label,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "year",
        fill: dataset?.backgroundColor,
        stroke: dataset?.borderColor,
      }));

      let tooltip: any = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        labelText: "{valueY}",
      });
      
      tooltip.get("background").setAll({
        fill: am5.color(dataset?.backgroundColor),
        fillOpacity: 1
      });
      
      series.set("tooltip", tooltip);


      series.data.setAll(hh);
      series.appear(1000);

    })

    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: root.horizontalLayout,
      scale: 0.75,
    }));
    legend.data.setAll(chart.series.values);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      xAxis: xAxis,
    }));

    cursor.lineX.setAll({
      stroke: am5.color('#000000'),
      strokeWidth: 130,
      strokeOpacity: 0.1,
      strokeDasharray: []
    });

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <div>
      <div id="chartdivBar" style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}


export { BarChart };
